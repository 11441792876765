<script>
import IDENTITY from '../queries/Identity.gql'

const SCRIPT_URL = '//fast.appcues.com/86949.js'

export default {
  data: () => ({
    appcues: null,
    identity: null,
    identified: false
  }),

  computed: {
    isInitialized () {
      return this.appcues && this.identity
    }
  },
  watch: {
    $route () {
      if (this.appcues) {
        this.appcues.page()
      }
    },

    isInitialized (value) {
      if (value && !this.identified) {
        const { user, company } = this.identity
        this.appcues.identify(user.id, {
          createdAt: user.createdAt,
          firstName: user.firstname,
          lastName: user.lastname,
          gender: user.gender,
          country: company.country?.toUpperCase(),
          companySlug: company.slug,
          companyName: company.name,
          productType: company.productType
        })
        this.identified = true
      }
    }
  },

  async created () {
    const script = document.createElement('script')
    script.setAttribute('src', SCRIPT_URL)
    script.addEventListener('load', () => {
      this.appcues = window.Appcues
    })
    document.head.appendChild(script)
  },
  methods: {
    useAppcues () {
      return Boolean(import.meta.env.VITE_APP_PRODUCT_APPCUES)
    }
  },

  apollo: {
    identity: {
      query: IDENTITY,
      skip () {
        return !this.useAppcues() || this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      }
    }
  },

  render () {
    return null
  }
}
</script>
