// src/plugins/vuetify.js
import { createVuetify } from 'vuetify'
import { de } from 'vuetify/locale'
import { md3 } from 'vuetify/blueprints'
import { appBranding } from '@/lib/features'

// Import styles
import 'vuetify/styles'

// Define CSS custom properties for gradients
const cssVariables = {
  '--markero-blue-gradient': 'linear-gradient(to right, #37C6F6, #377DF6)',
}

// Function to inject CSS variables
const injectCssVariables = () => {
  Object.entries(cssVariables).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value)
  })
}

// Call this when your app starts
injectCssVariables()

// Create custom theme
const light = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: (appBranding.isActive && appBranding.config.primary) || '#1668F5',
    secondary: (appBranding.isActive && appBranding.config.secondary) || '#424242',
    success: (appBranding.isActive && appBranding.config.success) || '#4CAF50',
    red: '#FA0143',
    orange: '#FE9930',
    error: '#FA0143',
    info: '#4895EF',
    warning: '#FE9930',
    leadActivity: '#07BEB8',
    systemActivity: '#4895EF',
    userActivity: '#C77DFF',
    skyLight: '#A2D2FF',
    dogerBlue: '#4895EF',

    // Markero colors
    'markero-black': '#000000',
    'markero-light-yellow': '#ffd518',
    'markero-mid-yellow': '#f9b004',
    'markero-orange': '#f18815',
    'markero-light-orange': '#FF8A00',
    'markero-blue': '#377df6',
    'markero-dark-blue': '#1668F5',
    'markero-light-blue': '#37C6F6',
    'markero-dark-grey': '#414142',
  }
}

export default createVuetify({
  blueprint: md3,
  locale: {
    locale: 'de',
    fallback: 'de',
    messages: { de }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light,
    },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 1,
      darken: 2,
    }
  },
  defaults: {
    VBtn: {
      variant: 'flat',
      height: 40,
    },
    VCard: {
      elevation: 1,
    },
  }
})
