query CompanyBase($id: String) {
  company(id: $id) {
    id
    name
    slug
    country
    productType
    hasFreeSignUp
    state {
      stringValues
    }
    clientAdvisor {
      id
      name
      email
      phone {
        ch
        de
        at
      }
      photo
      whatsappNumber
      calendlyUsername
    }
  }
}
