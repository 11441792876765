import { reactive, readonly } from 'vue'
import { getConsentCookie, setConsentCookie } from '@/lib/cookieHandling'

const state = reactive({
  choice: []
})

// Initialize from saved cookie if exists
try {
  const savedChoice = getConsentCookie()
  if (Array.isArray(savedChoice)) {
    state.choice = savedChoice
  }
} catch (err) {
  // eslint-disable-next-line
  console.warn('Invalid cookie consent data found')
}

export function useCookieConsent () {
  const accept = (choice) => {
    if (Array.isArray(choice)) {
      state.choice = choice
      setConsentCookie(choice)
    }
  }

  const hasAccepted = () => {
    return Boolean(state.choice) && !!state.choice.length
  }

  const hasTrackingConsent = () => {
    return state.choice.includes('tracking')
  }

  return {
    choice: readonly(state.choice),
    accept,
    hasAccepted,
    hasTrackingConsent
  }
}

// Get initial consent state without creating reactive refs
export function getInitialConsent () {
  return {
    choice: state.choice,
    hasTrackingConsent: () => state.choice.includes('tracking')
  }
}

// Plugin for backwards compatibility
export const cookieConsentPlugin = {
  install (app) {
    const cookieConsent = useCookieConsent()
    app.config.globalProperties.$cookieConsent = cookieConsent
    app.provide('cookieConsent', cookieConsent)
  }
}
