<template>
  <AuthView>
    <div class="flex-1">
      <h1 class="text-h4 font-weight-black text-center">
        Logout
      </h1>

      <h2 class="my-12 text-center">
        Bitte warten Sie einen Moment.
      </h2>
      <div class="d-flex w-full justify-space-around">
        <v-progress-circular
          indeterminate
          :size="200"
          :width="25"
          color="primary"
        />
      </div>
    </div>
  </AuthView>
</template>

<script>
import AuthView from './AuthView.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: { AuthView },
  mixins: [
    brandingMixin
  ],
  created () {
    this.$auth.logout()
    this.$router.push('/auth/login')
  }
}
</script>
