<template>
  <v-container class="pb-8 layout-container">
    <Permission permission="settings-subscription:read">
      <DunningAlert />
    </Permission>

    <DeletionAlert />

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <BaseHeader
        :title="baseHeaderTitle"
        :style-variant="1"
      >
        <template
          v-if="!hasScores || !feature.config.showLeadScoreChart"
          v-slot:design
        >
          <HeaderDesign />
        </template>
        <template
          v-if="hasScores && feature.config.showLeadScoreChart"
          v-slot:button
        >
          <v-btn
            icon
            size="small"
            class="toggle-btn"
            @click="isDetailsOpen = !isDetailsOpen"
          >
            <v-icon>
              {{ isDetailsOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </template>

        <template
          v-if="hasScores && feature.config.showLeadScoreChart"
          v-slot:charts
        >
          <div class="chart-wrapper">
            <ScoreChart
              :score="scores.overall"
              :label="$t('labels.score-chart.overall')"
            />
          </div>
        </template>

        <template v-slot:desc>
          <p>
            {{ $t('dashboard.baseHeader.text') }}
          </p>
        </template>

        <template
          v-if="hasScores&& feature.config.showLeadScoreChart"
          v-slot:content
        >
          <ScoreDetails
            :scores="scores"
            :is-details-open="isDetailsOpen"
          />
        </template>
      </BaseHeader>
    </Feature>

    <Permission permission="settings-subscription:read">
      <CanceledSubscriptionAlert />
    </Permission>

    <Feature :feature-slug="featureNames.CRM">
      <ActivationReminder />
    </Feature>

    <Feature :feature-slug="featureNames.SIGNUP">
      <UserMailVerification />
    </Feature>

    <email-verification
      v-if="showEmailVerification"
      :default-sender="defaultSender"
    />

    <Permission permission="mission-control:read">
      <Feature :feature-slug="featureNames.TODO">
        <Todo />
      </Feature>
    </Permission>

    <ProductFruitsChecklist
      v-if="useProductFruits"
    />

    <Permission permission="lead:read-all">
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <statistics v-if="(!showEmailVerification || isSenderVerified) && feature.config.showLeadStatistics" />
      </Feature>
    </Permission>

    <Permission permission="lead:invite-bulk">
      <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
        <AcquisitionBoosterDashboard
          :invitation-statistics-for-company="invitationStatisticsForCompany"
        />
      </Feature>
    </Permission>

    <Permission permission="ai:read">
      <Feature :feature-slug="featureNames.CONTENT_CREATOR">
        <ContentCreatorDasboard
          :invitation-statistics-for-company="invitationStatisticsForCompany"
        />
      </Feature>
    </Permission>

    <Permission permission="lead:read">
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row>
          <Feature :feature-slug="featureNames.VALUATION">
            <v-col
              cols="12"
              :md="feature.config.showLeadInteractivity ? 6 : 12"
            >
              <lead-map
                v-if="leads"
                :leads="leads"
              />
              <v-card
                v-else
                border
                class="mt-4 map-loader"
                style="height:400px"
              >
                <v-skeleton-loader
                  height="400px"
                  type="image"
                />
              </v-card>
            </v-col>
          </Feature>

          <v-col
            cols="12"
            md="6"
          >
            <lead-interactivity v-if="feature.config.showLeadInteractivity" />
          </v-col>
        </v-row>
      </Feature>
    </Permission>
  </v-container>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import HeaderDesign from '@/modules/dashboard/HeaderDesign.vue'
import Statistics from '@/modules/statistics'
import Todo from '@/modules/todo'
import LeadInteractivity from '@/modules/statistics/LeadInteractivity'
import LEADS from '@/modules/map/LeadsWithLocation.gql'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import BaseHeader from '@/components/BaseHeader.vue'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import ScoreChart from '@/modules/score/ScoreChart.vue'
import ScoreDetails from '@/modules/score/scoreDetails.vue'
import GET_COMPANY_SCORES from '@/modules/score/queries/GetCompanyScores.gql'
import INVITATION_STATISTICS_FOR_COMPANY from '@/modules/acquisitionBooster/InvitationStatisticsForCompany.gql'
import UserMailVerification from '@/modules/auth/UserMailVerification.vue'
import ActivationReminder from '@/modules/crm/components/ActivationReminder.vue'
import CanceledSubscriptionAlert from '@/modules/dashboard/CanceledSubscriptionAlert'
import DunningAlert from '@/modules/dashboard/DunningAlert'
import Permission from '@/components/Permission'
import DeletionAlert from '@/modules/dashboard/DeletionAlert.vue'
import ProductFruitsChecklist from '@/modules/productFruitsChecklist/index.vue'

export default {
  components: {
    ProductFruitsChecklist,
    LeadMap: defineAsyncComponent(() => import('@/modules/map')),
    Statistics,
    LeadInteractivity,
    BaseHeader,
    HeaderDesign,
    CanceledSubscriptionAlert,
    DunningAlert,
    EmailVerification: defineAsyncComponent(() => import('@/modules/emailVerification')),
    AcquisitionBoosterDashboard: defineAsyncComponent(() => import('@/modules/acquisitionBooster/AcquisitionBoosterDashboard')),
    ContentCreatorDasboard: defineAsyncComponent(() => import('@/modules/contentCreator/ContentCreatorDasboard/index')),
    ScoreDetails,
    ScoreChart,
    Todo,
    UserMailVerification,
    ActivationReminder,
    Permission,
    DeletionAlert
  },
  mixins: [brandingMixin, featureMixin],
  inject: ['role'],
  apollo: {
    leads: {
      query: LEADS,
      skip () {
        return !this.role.isAuthorizedFor('lead:read')
      }
    },
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      skip () {
        return !this.role.isAuthorizedFor('settings-email:read')
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      skip () {
        return !this.role.isAuthorizedFor('settings-email:read')
      }
    },
    getCompanyScores: {
      query: GET_COMPANY_SCORES,
      skip () {
        const companyFeature = this.getFeature(this.featureNames.COMPANY)
        return !companyFeature?.config.showLeadStatistics && !companyFeature?.config.showLeadScoreChart
      },
      result ({ data }) {
        if (data) {
          this.scores = data.getCompanyScores
        }
      }
    },
    invitationStatisticsForCompany: {
      query: INVITATION_STATISTICS_FOR_COMPANY,
      skip () {
        return !this.isFeatureActive(this.featureNames.ACQUISITION_BOOSTER) || !this.role.isAuthorizedFor('lead:invite-bulk')
      }
    }
  },
  data () {
    return {
      isDetailsOpen: false,
      scores: this.companyScores
    }
  },
  computed: {
    hasScores () {
      if (this.scores) {
        return Object.values(this.scores).some((score) => score !== null)
      }
      return false
    },
    showEmailVerification () {
      console.log(this.featureNames.HOPPERMATION)
      const hoppermationFeature = this.getFeature(this.featureNames.HOPPERMATION)
      if (
        this.$apollo.loading ||
        !hoppermationFeature.isActive ||
        !this.role.isAuthorizedFor('settings-email:read') ||
         hoppermationFeature.config?.hasStandardSender
      ) {
        return false
      }
      return this.organization.status !== 'ACTIVE' || !this.defaultSender.isAcknowledged
    },
    isSenderVerified () {
      return this.defaultSender?.isVerified && !this.defaultSender?.isAcknowledged
    },
    baseHeaderTitle () {
      return this.$auth.user.given_name ? `${this.$t('dashboard.baseHeader.title')},  ${this.$auth.user.given_name}!` : `${this.$t('dashboard.baseHeader.title')}`
    },
    useProductFruits () {
      return Boolean(import.meta.env.VITE_APP_PRODUCT_FRUITS_WORKSPACE_CODE)
    }
  }
}
</script>

<style>
.map-loader .v-skeleton-loader__image {
  height: 400px !important;
  border: none;
}

.toggle-btn {
  position: relative;
  left: 95%;
  top: 32px;
  z-index: 10;
}
@media (max-width: 1025px) {
  .toggle-btn {
    left: 90%;
  }
}

@media (min-width: 1265px) {
  .chart-wrapper {
    max-height: 80px !important;
    position: relative;
    bottom: 20px;
  }
}
</style>
