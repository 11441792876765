/* eslint-disable no-console */
import { nextTick, getCurrentInstance } from "vue"
import bus, { eventNames } from '@/lib/eventBus'

/**
 * Vue 3 component that represents a feature.
 * @property {Object} props - The properties of the component.
 * @property {String} props.featureSlug - The slug of the feature. It is required.
 * @property {Boolean} props.useIsEnabled - Show not only when the feature isActive but already when isEnabled. Default is false.
 * @slot default - The default slot for the component. Rendered when the feature isActive.
 * @slot inactive - The slot for the component when the feature is inactive. Rendered when the feature isEnabled but not isActive.
 */
export default {
  name: 'Feature',

  props: {
    featureSlug: {
      type: String,
      required: true
    },
    useIsEnabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      show: true,
      features: null
    }
  },

  created () {
    // Get features from global properties as fallback
    const app = getCurrentInstance()
    this.features = app?.appContext?.config?.globalProperties?.$features

    if (!this.features) {
      console.warn('Features not found in global properties')
      return
    }

    // Using the event bus instead of emitter
    bus.$on(eventNames.CHANGE_FEATURE_CONFIG, this.handleFeatureChange)
  },

  unmounted () {
    // Using unmounted instead of destroyed in Vue 3
    bus.$off(eventNames.CHANGE_FEATURE_CONFIG, this.handleFeatureChange)
  },

  methods: {
    async handleFeatureChange () {
      this.show = false
      await nextTick()
      this.show = true
    },

    getFeature () {
      if (!this.features) return null

      try {
        // Try to access feature method through the prototype chain
        const feature = typeof this.features.feature === 'function'
          ? this.features.feature(this.featureSlug)
          : Object.prototype.hasOwnProperty.call(this.features, this.featureSlug)
            ? this.features[this.featureSlug]
            : null

        return feature
      } catch (error) {
        console.error(`Error getting feature for slug ${this.featureSlug}:`, error)
        return null
      }
    }
  },

  render () {
    const feature = this.getFeature()

    if (!feature) {
      return null
    }

    if (this.show && (feature.isActive || (this.useIsEnabled && feature.isEnabled))) {
      // Vue 3 slots API changes
      if (this.$slots.default) {
        return this.$slots.default({
          feature
        })
      }
    } else if (feature && feature.isEnabled) {
      if (this.$slots.inactive) {
        return this.$slots.inactive({ feature })
      }
    } else {
      if (this.$slots.inactive) {
        return this.$slots.inactive({ feature })
      }
    }
    return null
  }
}
