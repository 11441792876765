query Statistics ($companyId: String!) {
  statistics (companyId: $companyId) {
    totalLeadValue
    totalValuationCount
    totalCommissionValue {
      value
      commission
    }
    totalLeadCount{
      total
      withValuation
    }
    totalLeadCountByStatus {
      new
      unreached
      newWithValuation
    }
    totalTrafficSource {
      count
      type
    }
  }
}