query GetCompanyScores {
  getCompanyScores {
    emailScore
    phoneScore
    valuationScore
    propertyRequestScore
    emailAmount
    phoneAmount
    valuationAmount
    propertyRequestAmount
    overall
  }
}
