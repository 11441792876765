/* eslint-disable no-console */

import { reactive } from 'vue'
import FEATURES_FOR_COMPANY from '../queries/FeaturesForCompany.gql'
import FEATURES from '../queries/Features.gql'
import SET_COMPANY_FEATURE from '../queries/SetCompanyFeature.gql'

let instance

export const getInstance = () => instance

export const useFeaturesPlugin = ({ onCreated, bus: emitter, apolloProvider }) => {
  if (instance) return instance

  instance = reactive({
    _features: [],
    _featuresBySlug: {},
    _isVisible: true,
    _apolloProvider: apolloProvider,
    _loadInterval: null,
    loaded: false,
    emitter,

    /**
     * Features.
     * @returns {[Feature]} Features Array
     */
    get features () {
      // loaded or any simple data type is needed for computed prop to be reactive
      return this.loaded ? this._features : []
    },

    /**
     * Features by slug.
     * @returns {Record<string, Feature>} Object with `{ [slug]: feature }`
     */
    get featuresBySlug () {
      // loaded or any simple data type is needed for computed prop to be reactive
      return this.loaded ? this._featuresBySlug : {}
    },

    created () {
      onCreated.call(this)
      this._isVisible = true
      document.addEventListener("visibilitychange", () => {
        this._isVisible = document.visibilityState === 'visible'
      })
    },

    unmounted () {
      if (this._loadInterval) {
        clearInterval(this._loadInterval)
      }
    },

    /**
     * Load all features for platform.
     * @param {Boolean} [reload=true]  For `false` only load features if never loaded before
     */
    async loadAllFeatures ({ reload = true } = {}) {
      if (!reload && this._featuresBySlug) return

      if (!this._apolloProvider?.defaultClient) {
        console.error('Apollo client is not initialized')
        return
      }

      const apolloClient = this._apolloProvider.defaultClient
      try {
        const { data } = await apolloClient.watchQuery({
          query: FEATURES,
          fetchPolicy: reload ? 'network-only' : 'cache-first',
          nextFetchPolicy: 'cache-first'
        }).result()

        if (data?.features) {
          this._features = data.features
          this._featuresBySlug = this._getFeaturesBySlug(data.features)
          this.loaded = true
        }
      } catch (e) {
        console.error('Error loading features:', e.message || e)
        if (this.emitter) {
          this.emitter.$emit('featureConfigError', e)
        }
      }
    },

    /**
     * Load all features for company.
     * @param {ObjectId} companyId  CompanyId
     * @param {Boolean} [reload=true]  For `false` only load features if never loaded before
     * @param {Number} [interval=-1]  Reload features in the provided interval, default is -1 ms = no interval
     *
     * @event changedFeatureConfig Emitted when feature config has changed
     * @event featureConfigError Emitted when an error occurs during feature loading
     */
    async loadAllFeaturesForCompany (companyId, { reload = true, interval = -1 } = {}) {
      if (!reload && this.loaded) return

      if (!this._apolloProvider?.defaultClient) {
        console.error('Apollo client is not initialized')
        return
      }

      const apolloClient = this._apolloProvider.defaultClient

      const fetchData = async () => {
        try {
          // Use regular query with network-only policy
          const response = await apolloClient.query({
            query: FEATURES_FOR_COMPANY,
            variables: { companyId },
            fetchPolicy: 'network-only'
          })

          if (response?.data?.featuresForCompany) {
            this._features = response.data.featuresForCompany
            this._featuresBySlug = this._getFeaturesBySlug(response.data.featuresForCompany)
            this.loaded = true

            if (this.emitter) {
              this.emitter.$emit('changedFeatureConfig')
            }
          }
        } catch (e) {
          console.error('Error fetching features:', e.message || e)
          if (this.emitter) {
            this.emitter.$emit('featureConfigError', e)
          }
        }
      }

      await fetchData()

      if (interval > 0) {
        if (this._loadInterval) clearInterval(this._loadInterval)
        this._loadInterval = setInterval(async () => {
          if (this._isVisible) {
            await fetchData()
          }
        }, interval)
      }
    },

    /**
     * Load all features for company and returns them.
     * @param {ObjectId} companyId  CompanyId
     * @returns {Promise<Record<string, Feature>>} Object with `{ [slug]: feature }`
     */
    async getFeaturesForCompany (companyId) {
      if (!this._apolloProvider?.defaultClient) {
        console.error('Apollo client is not initialized')
        return {}
      }

      const apolloClient = this._apolloProvider.defaultClient

      try {
        const { data } = await apolloClient.watchQuery({
          query: FEATURES_FOR_COMPANY,
          variables: { companyId },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-first'
        }).result()

        return data?.featuresForCompany ?
          this._getFeaturesBySlug(data.featuresForCompany) :
          {}
      } catch (e) {
        console.error('Error getting features:', e.message || e)
        if (this.emitter) {
          this.emitter.$emit('featureConfigError', e)
        }
        return {}
      }
    },

    /**
     * Get feature for slug.
     * @param {string} featureSlug
     * @returns {Feature|undefined}
     */
    feature (featureSlug) {
      return this._featuresBySlug && this._featuresBySlug[featureSlug]
    },

    /**
     * Convert feature array to object with slugs as keys
     * @private
     */
    _getFeaturesBySlug (featureArray) {
      return featureArray.reduce((obj, feature) => {
        obj[feature.slug] = feature
        return obj
      }, {})
    },

    /**
     * Create or update a feature for a company.
     * @param {ObjectId} companyId  CompanyId
     * @param {String} [slug]  The slug of the feature which should be updated
     * @param {boolean} [isActive]  The isActive flag which the user can change (optional)
     * @param {Object} [config]  The config which should be changed for the feature (optional)
     *
     * @event changeFeatureConfig
     * @type {object}
     * @property {string} slug - Indicates which feature has been changes by slug
     * @property {object} error - Contains errors if any occurred
     */
    async setCompanyFeature ({ companyId, slug, isActive, config }) {
      if (!slug || !this._apolloProvider?.defaultClient) {
        console.error('Missing slug or Apollo client not initialized')
        return
      }

      const apolloClient = this._apolloProvider.defaultClient
      try {
        const { data: { setCompanyFeature } } = await apolloClient.mutate({
          mutation: SET_COMPANY_FEATURE,
          variables: {
            input: { companyId, slug, isActive, config }
          }
        })

        this._features = setCompanyFeature
        this._featuresBySlug = this._getFeaturesBySlug(setCompanyFeature)

        if (this.emitter) {
          this.emitter.$emit('changeFeatureConfig', { slug })
        }
      } catch (error) {
        console.error('Error setting company feature:', error.message || error)
        if (this.emitter) {
          this.emitter.$emit('changeFeatureConfig', { slug, error })
        }
      }
    }
  })

  instance.created()
  return instance
}

/**
 * FeaturePlugin add `$features` to Vue.
 * @param {import('vue').App} app - Vue 3 app instance
 * @param {Object} options - Plugin options
 * @param {Function} options.onCreated - Callback when Plugin is created
 * @param {Object} options.bus - Event bus instance
 * @param {Object} options.apolloProvider - Apollo provider instance
 */
export const FeaturePlugin = {
  install (app, options) {
    if (!options.apolloProvider) {
      console.error('FeaturePlugin requires apolloProvider to be provided in options')
      return
    }

    const features = useFeaturesPlugin({
      onCreated: options.onCreated,
      bus: options.bus,
      apolloProvider: options.apolloProvider
    })

    app.config.globalProperties.$features = features
    app.provide('features', features)
  }
}
