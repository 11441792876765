mutation setCompanyFeature($input: SetCompanyFeatureInput!) {
  setCompanyFeature(input: $input){
    slug
    name
    isEnabled
    isDefaultActive
    isUpsellingEnabled
    config
    isActive
  }
}