import { inject } from 'vue'

export function useGTM () {
  const gtm = inject('gtm', null)

  const enableGTM = (enabled) => {
    if (gtm) {
      gtm.enable(enabled)
    }
  }

  return {
    gtm,
    enableGTM
  }
}
