// mixins/global.js
import date from './date'

export default {
  install (app) {
    app.mixin({
      ...date
    })
  }
}
