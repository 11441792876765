import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router'
import vuetify from './plugins/vuetify'
import TrackingPlugin from './plugins/tracking'
// import CookieConsentPlugin from './modules/cookieConsent/plugin'
import { createAuth0 } from './auth/auth0'
import { onofficeAuth } from './auth/onoffice'
import { AuthPlugin } from './auth'
import { FeaturePlugin } from '@/plugins/feature-config/'
import { createProvider } from './vue-apollo'
import * as Sentry from '@sentry/vue'
import GlobalMixin from './mixins/global'
import './registerServiceWorker'
import '@/lib/animation'
import { createGtm } from '@gtm-support/vue-gtm'
import bus, { eventNames } from '@/lib/eventBus'
import { getInitialConsent } from '@/services/cookieConsent'
import { getSignUpGuard } from '@/lib/signUpGuard'
import { getLicenseAgreementGuard } from '@/lib/licenseAgreementGuard'
import { getAvvGuard } from '@/lib/avvGuard'
import { hasCompanyGuard } from './lib/companyGuard'
import i18n from './i18n'

const app = createApp(App)

// Initialize Apollo Provider
const apolloProvider = createProvider().install(app)

// Create Router after Apollo is initialized
const router = createRouter(apolloProvider)

// Rest of plugins that don't depend on Apollo
// app.use(CookieConsentPlugin)
app.use(TrackingPlugin, { apolloProvider })
app.use(GlobalMixin)

// Auth0 Configuration - needs to be before Feature Plugin
const auth0Plugin = createAuth0({
  domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
  audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
  databaseConnection: import.meta.env.VITE_APP_AUTH0_DATABASE_CONNECTION,
  redirectUri: window.location.origin + '/auth/callback',
  onRedirectCallback: targetPath => {
    router.push({
      ...(targetPath
        ? { path: targetPath }
        : { name: 'dashboard' }
      ),
      replace: true
    })
  }
})

app.use(AuthPlugin, {
  auth0: auth0Plugin,
  onofficeAuth
})

// Feature Plugin Configuration
app.use(FeaturePlugin, {
  bus,
  apolloProvider,
  onCreated () {
    bus.$on(eventNames.USER_LOGGED_IN, (companyId) => {
      if (companyId) {
        this.loadAllFeaturesForCompany(companyId, { interval: 900000 })
      }
    })
  }
})

// GTM Configuration
let gtmInstance = null
if (import.meta.env.VITE_APP_GTM_ID) {
  const { hasTrackingConsent } = getInitialConsent()
  gtmInstance = createGtm({
    id: import.meta.env.VITE_APP_GTM_ID,
    enabled: hasTrackingConsent()
  })
  app.use(gtmInstance)
  // Provide GTM instance to components
  app.provide('gtm', gtmInstance)
}

// Sentry Configuration
Sentry.init({
  app,
  attachProps: true,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_STAGE,
  enabled: import.meta.env.VITE_APP_STAGE && import.meta.env.VITE_APP_STAGE !== 'local'
})

// Router Guards
router.beforeEach(hasCompanyGuard())
router.beforeEach(getSignUpGuard(apolloProvider))
router.beforeEach(getLicenseAgreementGuard(apolloProvider))
router.beforeEach(getAvvGuard(apolloProvider))

// Mount Application
app
  .use(router)
  .use(i18n.getInstance(apolloProvider.defaultClient))
  .use(vuetify)
  .mount('#app')
