query Identity {
  identity {
    user {
      id
      email
      firstname
      lastname
      gender
      createdAt
      role {
        id
        name
        slug
        permissions
      }
      appLocale
    }
    company {
      id
      name
      slug
      country
      productType
    }
  }
}
