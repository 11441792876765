<template>
  <div
    :style="cssProps"
    class="h-full"
  >
    <v-row
      align="center"
      justify="center"
      class="h-full pa-0 ma-0"
    >
      <v-col
        v-if="isPlatformMarkero && !$vuetify.display.smAndDown"
        class="col-6 pa-0"
      >
        <div
          class="markero-logo pa-0 px-10"
        >
          <v-img
            alt="Company Registration Logo"
            :src="markeroRegistrationImg"
            cover
          />
        </div>
      </v-col>
      <v-col
        v-else
        cols="6"
        class="justify-center px-5 ma-0 d-none d-md-flex align-center login-hero"
      >
        <GradientBackground
          :top-left="gradientColors.start"
          :top-right="gradientColors.end"
          :bottom-left="gradientColors.start"
          :bottom-right="gradientColors.end"
        />
        <Tilt
          :options="{max:10}"
          class="overlay"
        >
          <div class="hero-wrapper">
            <Blob
              :width="heroWidth+'px'"
              fill-color-start="#ffffff"
              fill-color-stop="#ededed"
              fill
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/10)+'px'"
              :style="getDefaultStyle(30,0,1)"
              stroke
              :stroke-width="1"
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/20)+'px'"
              :style="getDefaultStyle(30,0,1)"
              fill
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/5)+'px'"
              :style="getDefaultStyle(-200,-150,0.6)"
              fill
            />

            <SingleCircle
              class="hero-design-element"
              :width="parseInt(heroWidth/10)+'px'"
              :style="getDefaultStyle(-100,50,1)"
              stroke
            />

            <SingleCircle
              class="hero-design-element"
              :width="parseInt(heroWidth/7)+'px'"
              :style="getDefaultStyle(-30,60,0.8)"
              fill
            />
          </div>
        </Tilt>

        <v-img
          :alt="$t('navigation.logo.alt-texts.0')"
          class="login-logo"
          :src="logoLightImg"
          width="140"
          cover
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="justify-center h-full login-container ma-0 pa-0 d-flex"
      >
        <v-col
          cols="12"
          sm="8"
          :class=" isPlatformMarkero ? 'login-container-markero' : 'justify-space-between'"
          class="h-full inner-container d-flex  flex-column"
        >
          <div
            class="flex-1"
          >
            <v-img
              :alt="$t('navigation.logo.alt-texts.0')"
              class="mt-2 ml-auto mr-auto d-block d-md-none"
              :src="logoImg"
              width="140"
              cover
            />
          </div>

          <slot />

          <div
            class="justify-center flex-1 mb-2 d-flex flex-wrap"
            :class="{'markero': isPlatformMarkero}"
          >
            <template v-if="useIubenda">
              <IubendaDocumentLink type="privacyPolicy" />
              <IubendaConsentLink class="ml-4" />
              <IubendaDocumentLink
                type="terms"
                class="ml-4"
              />
            </template>
            <a
              v-else
              :href="publicDataPrivacyUrl"
              target="_blank"
              class="text-primary"
              rel="noopener noreferrer"
            >{{ $t('navigation.labels.dsgvo') }}</a>
            <a
              :href="imprintUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="ml-4 text-primary"
            >{{ $t('navigation.labels.imprint') }}</a>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { iubenda } from '@/lib/features'
import Tilt from '@/components/Tilt.vue'
import brandingMixin from '@/mixins/branding'
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import Blob from '@/components/design-elements/blob.vue'
import GradientBackground from '@/components/GradientBackground.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'

// Import images
import markeroRegistrationImg from '@/assets/img/markero-registration.png'
import logoLightImg from '@/assets/img/logo-light.png'
import logoImg from '@/assets/img/logo.png'

export default {
  name: 'AuthView',

  components: {
    Tilt,
    Leaf,
    SingleCircle,
    Blob,
    GradientBackground,
    IubendaDocumentLink,
    IubendaConsentLink
  },

  mixins: [brandingMixin],

  data () {
    return {
      heroWidth: 500,
      useIubenda: iubenda?.isActive,
      markeroRegistrationImg,
      logoLightImg,
      logoImg
    }
  },

  computed: {
    cssProps () {
      return {
        '--mid-yellow': this.$vuetify.theme.global?.current?.colors?.markero?.midYellow ?? '#FFEB3B'
      }
    }
  },

  methods: {
    getDefaultStyle (top, right, opacity) {
      return {
        top: this.relativePos(top) + 'px',
        right: this.relativePos(right) + 'px',
        opacity
      }
    },
    relativeHeroElementWidth (x) {
      return parseInt(this.heroWidth / x)
    },
    relativePos (x) {
      return parseInt(this.heroWidth / 2) + x
    }
  }
}
</script>

<style scoped>
.login-container{
  background-color: white;
}

.inner-container{
  max-width: 500px;
}

.login-hero{
  position: relative;
  height: 100%;
}

.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.hero-wrapper{
  position: relative;
}

.hero-design-element{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.overlay{
  z-index: 2;
  position: relative;
}

.markero-logo {
  height: 100vh;
  background-color: var(--mid-yellow);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.markero a {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 960px) {
  .login-container-markero {
    gap: 2rem;
  }
}

@media (min-width: 960px) {
  .login-container-markero {
    justify-content: space-between;
  }
}
</style>
