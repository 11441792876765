export default {
  methods: {
    formatPrice (price, currency, fractionDigits = 0) {
      const { locale = 'de-DE' } = this.$auth?.user || {}
      const currencies = { 'de-DE': 'EUR', 'de-AT': 'EUR', 'de-CH': 'CHF', 'tr-TR': 'TRY' }

      // Ensure we have a valid currency
      const currencyToUse = currency || currencies[locale] || 'EUR' // Fallback to EUR

      try {
        return new Intl.NumberFormat(locale, {
          currency: currencyToUse,
          style: 'currency',
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        }).format(price)
      } catch (error) {
        // Fallback to default locale and EUR if something goes wrong
        return new Intl.NumberFormat('de-DE', {
          currency: 'EUR',
          style: 'currency',
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        }).format(price)
      }
    }
  }
}
