<template>
  <v-row :class="isHighlighted ? 'primary-gradient-background white--text rounded-lg' : ''">
    <v-col
      cols="12"
    >
      <span class="text-subtitle-1">
        <strong>{{ todo.title }}</strong>
      </span>
      <p class="ma-0 pa-0">
        <v-alert
          v-if="isCompleting && !isDone"
          class="mt-1"
          type="warning"
          variant="tonal"
          :icon="false"
        >
          <strong>{{ $t('mission-control.accomplished.title') }}</strong>
          <p>{{ $t('mission-control.accomplished.text') }}</p>
        </v-alert>
        <template v-if="!isCompleting">
          {{ todo.text }}
        </template>
      </p>
    </v-col>
    <v-col
      cols="12"
      :lg="isDone ? '3' : '2'"
      class="d-flex justify-start"
    >
      <v-btn
        size="small"
        :data-appcues="'trigger-' + todo.slug"
        elevation="0"
        class="ma-0"
        @click="isDone ? triggerAppcuesFlow() : startTodo()"
      >
        {{ isDone ? $t('buttons.mission-control.start-again') : $t('buttons.mission-control.start') }}
      </v-btn>
    </v-col>
    <v-col
      class="d-flex items-center mt-1"
      cols="12"
      lg="4"
      md="4"
      sm="12"
    >
      <span>
        {{ $t('labels.mission-control.status') }}:
      </span>
      <div
        class="d-flex flex-row"
        :class="{'selectable': !isDone}"
        @click="!isDone ? completeTodoWithDelay(null) : null"
      >
        <v-progress-circular
          v-if="isCompleting"
          size="20"
          width="3"
          class="ml-1 mt-px"
          indeterminate
          color="warning"
        />
        <v-icon
          v-else
          class="ml-1 mb-1"
          style="opacity: 0.8"
          :color="isHighlighted ? 'white' : checkColor"
        >
          {{ isDone ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon>
        <span class="ml-1">
          {{ $t('labels.mission-control.finished') }}
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import todoStates from '@/modules/todo/enums/ToDoStates'
import { triggerAppcuesFlow } from '@/lib/appcues'

const MISSION_DELAY = 4000

export default {
  props: {
    todo: {
      type: Object,
      required: true
    },
    isHighlighted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCompleting: false
    }
  },
  computed: {
    checkColor () {
      return this.isDone ? 'primary' : 'grey'
    },
    isDone () {
      return this.todo.state === todoStates.FINISHED
    },
    isOpen () {
      return this.todo.state === todoStates.OPEN
    },
    isStarted () {
      return this.todo.state === todoStates.STARTED
    },
    hasAppcuesFlow () {
      return Boolean(this.todo.externalFlowId)
    }
  },
  methods: {
    triggerAppcuesFlow () {
      triggerAppcuesFlow({ externalFlowId: this.todo.externalFlowId })
    },
    startTodo () {
      if (this.hasAppcuesFlow) {
        this.triggerAppcuesFlow()
      }
      this.$emit('startTodo', { slug: this.todo.slug })
    },
    completeTodo (endConditionEvent) {
      this.$emit('completeTodo', { slug: this.todo.slug, endConditionEvent })
    },
    completeTodoWithDelay () {
      this.isCompleting = true
      setTimeout(() => {
        this.$emit('completeTodo', { slug: this.todo.slug, endConditionEvent: null })
        this.isCompleting = false
      }, MISSION_DELAY)
    }
  },
  emits: ["startTodo", "completeTodo"]
}
</script>

<style scoped>
  .selectable {
    cursor: pointer;
  }
</style>
