import { getInstance } from '@/plugins/feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'
import featureNames from '@/lib/featureNames'
import COMPANY_STATE from '@/guards/CompanyState.gql'

/**
 * Checks if sign up feature is active.
 * If so check is user is in SELF_ONBOARDING and show registration wizard.
 * If not, route to dashboard.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getSignUpGuard (apolloProvider) {
  return (to, from, next) => {
    authGuard(async () => {
      try {
        const auth = getAuthInstance()
        // Allow unauthenticated access
        if (!auth.isAuthenticated) {
          next()
          return
        }

        // Legal pages that have to be visible
        if (['privacy', 'logout'].includes(to.name)) {
          next()
          return
        }

        const featureConfig = getInstance()
        await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, { apolloProvider, reload: false })
        const signUpFeature = featureConfig.featuresBySlug[featureNames.SIGNUP]

        if (signUpFeature?.isActive) {
          const apolloClient = apolloProvider.defaultClient
          const { data: { company } } = await apolloClient.query({
            query: COMPANY_STATE,
            variables: { id: auth.user.companyId },
            fetchPolicy: 'no-cache'
          })

          if (!company) {
            throw new Error('Company not found')
          }

          const isSelfOnboarding = company?.state?.stringValues.includes('SELF_ONBOARDING')

          // Allow access to registration if already there
          if (isSelfOnboarding && to.name === 'registration') {
            next()
            return
          }

          // Redirect to registration if in self-onboarding
          if (isSelfOnboarding) {
            next({ name: 'registration', replace: true })
            return
          }

          // Redirect to dashboard if trying to access registration without self-onboarding
          if (to.name === 'registration') {
            next({ name: 'dashboard', replace: true })
            return
          }
        } else if (to.name === 'registration') {
          // Redirect to dashboard if sign up feature is not active
          next({ name: 'dashboard', replace: true })
          return
        }

        next()
      } catch (error) {
        console.error('Sign up guard error:', error)
        next(false)
      }
    })
  }
}
