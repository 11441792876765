/**
* Vue I18n is an internationalization plugin for Vue.js. It easily integrates localization features.
*
* This file creates a vue-i18n instance with the following configuration:
*    const i18n = createI18n({
*      locale: 'en', // set locale
*      messages, // set locale messages
*    })
*
* Usage:
*
* In Vue components, you can now use the t method to translate your application.
* Template example:
*    <p>{{ t('foo.bar') }}</p>
*
*    <MyComponent :label="t('foo.bar')" />
*
* Script example using Composition API:
*    import { useI18n } from 'vue-i18n'
*    const { t } = useI18n()
*    const label = t('foo.bar')
*
* You can change the locale using the locale.value property:
*    const { locale } = useI18n()
*    locale.value = 'fr'
*
* The translation messages are located in the src/i18n directory. Each language has its own file.
*
* For more information, check out the official Vue I18n documentation: https://vue-i18n.intlify.dev/
*/
import { createI18n } from 'vue-i18n'
import { watch } from 'vue'
import features from '@/lib/features'
import deepMergeObjects from '@/lib/deepMerge'
import { getInstance as getAuthInstance } from '@/auth'
import IDENTITY from '@/queries/Identity.gql'

// Import translations using Vite's glob import
const translations = import.meta.glob('@/i18n/*.json', {
  eager: true,
  import: 'default'
})

// Process translations into messages object
const messages = {
  tr: {},
  en: {},
  de: {}
}

// Parse translations from glob imports
Object.entries(translations).forEach(([path, translation]) => {
  const lang = path.match(/\/([^/]+)\.json$/)[1]
  if (messages[lang]) {
    messages[lang] = translation
  }
})

// Import all translation overrides
const translationOverrides = import.meta.glob('/i18n/*.json', {
  eager: true,
  import: 'default'
})

// As for BOTTIMMO we currently don't have the i18n feature active,
// so we need to make sure that German is the default language.
const FALLBACK_LOCALE = 'en'

const STORAGE_KEY = 'appLocale'

/**
* Check if i18n feature is enabled
* @returns {boolean}
*/
function isI18nEnabled () {
  return features?.i18n?.isActive
}

/**
* Load platform specific translations if they are configured in the i18n feature
*
* @param {string} language
* @returns {object}
*/
function platformsSpecificTranslation (language) {
  if (features?.i18n?.config?.languageOverrides?.[language]) {
    const fileName = features.i18n.config.languageOverrides[language]
    try {
      const filePath = `/i18n/${fileName}.json`
      const translation = translationOverrides[filePath]
      return translation || null
    } catch (error) {
      console.error('Error loading translation override:', error)
      return null
    }
  }
  return null
}

// Merge base translations with overrides synchronously
Object.entries(messages).forEach(([lang, baseTranslations]) => {
  const override = platformsSpecificTranslation(lang)
  if (override) {
    messages[lang] = deepMergeObjects(baseTranslations, override)
  }
})

const availableLocales = Object.keys(messages).filter((locale) =>
  features?.i18n?.config?.availableLocales?.includes(locale)
)

function getLocaleFromStorage () {
  return localStorage.getItem(STORAGE_KEY)
}

function saveLocaleToStorage (locale) {
  localStorage.setItem(STORAGE_KEY, locale)
}

function getBrowserLocale () {
  for (const browserLocale of window.navigator.languages) {
    if (availableLocales.includes(browserLocale)) {
      return browserLocale
    } else {
      const language = browserLocale.split('-')[0]
      if (availableLocales.includes(language)) {
        return language
      }
    }
  }
}

function getFallbackLocale () {
  return isI18nEnabled() ? features.i18n.config.defaultLocale : FALLBACK_LOCALE
}

/**
* Get the locale with which the plugin should be initialized.
* It considers the following sources in order:
* 1. Local storage
* 2. Browser locale
* 3. Fallback locale
*
* @returns {string} locale
*/
function getClientLocale () {
  let locale = getFallbackLocale()

  if (isI18nEnabled()) {
    const selectedLocale = getLocaleFromStorage()
    if (selectedLocale) {
      locale = selectedLocale
    } else {
      const browserLocale = getBrowserLocale()
      if (browserLocale) {
        locale = browserLocale
      }
    }
  }

  return locale
}

/**
* It fetches the user's locale from the server and sets it as the app's locale.
* Usually the locale from the server will be the same but can change if e.g.
* the user changes it on a different device.
*
* @param {Object} apolloClient
* @param {Object} i18nInstance i18n instance
*/
async function usePersistedLocale (apolloClient, i18nInstance) {
  const auth = getAuthInstance()
  watch(() => auth.isAuthenticated, async (isAuthenticated) => {
    if (isAuthenticated) {
      const result = await apolloClient.query({ query: IDENTITY })
      const { user } = result.data.identity

      if (user.appLocale && user.appLocale !== i18nInstance.global.locale.value) {
        i18nInstance.global.locale.value = user.appLocale
        saveLocaleToStorage(user.appLocale)
      }
    }
  }, { immediate: true })
}

const i18n = {
  _instance: null,

  availableLocales,

  /**
   * It initializes the i18n instance (if not already initialized) and returns it.
   *
   * @param {Object} apolloClient
   * @returns {Object} i18n instance
   */
  getInstance (apolloClient = null) {
    if (!this._instance) {
      this._instance = createI18n({
        locale: getClientLocale(),
        fallbackLocale: getFallbackLocale(),
        messages,
        legacy: false // Enable Composition API mode
      })
    }

    if (apolloClient) {
      usePersistedLocale(apolloClient, this._instance)
    }

    return this._instance
  },

  /**
   * It changes the locale of the i18n instance and saves it to the local storage.
   * @param {string} locale
   */
  changeLocale (locale) {
    const instance = i18n.getInstance()
    instance.global.locale.value = locale
    saveLocaleToStorage(locale)
  }
}

export default i18n
