query GetToDos($input: GetToDoInput!) {
  todos: getToDos(input: $input) {
    slug
    state
    title
    text
    buttonText
    updatedAt
    externalFlowId
    endConditionManual
    startConditionManual
    endConditionEvent
    startConditionEvent
    priority
  }
}
