<template>
  <v-card border>
    <v-card-title>
      {{ $t('reset-password.confirmation.title') }}
    </v-card-title>

    <v-card-text>
      <p>
        {{ $t('reset-password.confirmation.subtitle.0') }}
        <strong class="text-no-wrap">{{ email }}</strong> {{ $t('reset-password.confirmation.subtitle.1') }}
      </p>
      <p>
        {{ $t('reset-password.confirmation.subtitle.2') }}
      </p>
      <router-link :to="{ name: 'login' }">
        {{ $t('reset-password.confirmation.link') }}
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    email: {
      type: String,
      default: ''
    }
  }
}
</script>
