const getCookie = (cookieName) => {
  const cookies = decodeURIComponent(document.cookie).split(';')
  const parsedCookies = cookies.reduce((res, c) => {
    const splitted = c.split('=')
    if (splitted.length !== 2) {
      return res
    }

    const k = splitted[0].trim()
    const v = splitted[1].trim()
    res[k] = v
    return res
  }, {})

  return parsedCookies[cookieName] && JSON.parse(parsedCookies[cookieName])
}

const setCookie = (cookieName, cookieValue, expiresInDays = 365) => {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + expiresInDays)
  const expires = 'expires=' + expirationDate.toUTCString()
  document.cookie = `${cookieName} = ${JSON.stringify(cookieValue)}; ${expires};path=/`
}

const CONSENT_COOKIE_NAME = 'cookieConsentChoice'
export const getConsentCookie = () => getCookie(CONSENT_COOKIE_NAME)
export const setConsentCookie = (value) => setCookie(CONSENT_COOKIE_NAME, value)
