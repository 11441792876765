query Company($id: String) {
  company(id: $id) {
    id
    name
    slogan
    country
    variant
    customerNumber
    leadCorrespondenceBccEmail
    isReferenceCustomer
    productType
    bookedLandingpageSlugs
    commission
    mailProvider
    deleteOn
    clientAdvisor {
      id
      name
      email
      phone {
        ch
        de
        at
      }
      photo
      whatsappNumber
      calendlyUsername
    }
    address {
      city
      country
      street
      zip
    }
    links {
      about
      imprint
      privacy
      website
    }
    images {
      logo {
        url
        name
        id
      }
      team {
        url
        name
        id
      }
      office {
        url
        name
        id
      }
      region {
        url
        name
        id
      }
      lpValuationHero {
        url
        name
        id
      }
    }
    theme {
      colors {
        primary
        secondary
      }
    }
    billing {
      vatId
      vatIdRequestDate
      iban
      bic
      paymentMethod
      sepaValidAsOf
      hasSeparateBillingAddress
      contact {
        firstname
        lastname
        email
        phone
        companyName
        companyNameAddition
      }
      address {
        street
        zip
        city
      }
    }
    ambassador {
      id
      salutation
      firstname
      lastname
      name
      role
      email
      image {
        url
        id
      }
      phone
      mobile
      signature {
        html
        text
        customTemplateSignature
      }
    }
    contactPersons {
      id
      type
      companyName
      salutation
      firstname
      lastname
      email
      phone
      mobile
      note
      address {
        street
        city
        zip
        country
      }
    }
    performance {
      facebook {
        link
        budget
        distributeBudget
        zip
        onboarding {
          pages
          adAccounts
          leadgenTos
        }
      }
      google {
        targetUrl
        budget
        distributeBudget
        email
        zip
      }
    }
    customWording {
      salutation
      farewell
    }
  }
}
