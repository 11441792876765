import { getInstance } from '../auth/index'
import { watch } from 'vue'

const authGuard = (fn) => {
  const authService = getInstance()

  if (!authService.loading) {
    return fn()
  }

  const stopWatch = watch(
    () => authService.loading,
    (loading) => {
      if (loading === false) {
        fn()
        stopWatch()
      }
    },
    { immediate: true }
  )
}

const authenticated = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    if (authService.isAuthenticated) {
      return next()
    }

    authService.rememberLocationBeforeLogin()
    return next({ name: 'login' })
  })
}

const publicOnly = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    if (!authService.isAuthenticated) {
      return next()
    }

    return next({ name: 'dashboard', replace: true })
  })
}

export {
  authGuard,
  authenticated,
  publicOnly
}
